'use strict';

var $ = require('../internals/export');
var aWeakSet = require('../internals/a-weak-set');
var add = require('../internals/weak-set-helpers').add;

// `WeakSet.prototype.addAll` method
// https://github.com/tc39/proposal-collection-methods
$({
  target: 'WeakSet',
  proto: true,
  real: true,
  forced: true
}, {
  addAll: function addAll(/* ...elements */
  ) {
    var set = aWeakSet(this);
    for (var k = 0, len = arguments.length; k < len; k++) {
      add(set, arguments[k]);
    }
    return set;
  }
});